import React, { useContext, useEffect, useState } from "react";
import TableAgentReport from "components/TableAgentReport";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from 'use-debounce';
import refactorStructureData from "../../helpers/refactorDataMenu";
import { RootContext } from '../../App';

const divTotal = {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    height: '100px',
    paddingTop: '20px',
    paddingRight: '40px',
    borderRadius: '24px',
    border: 'solid 2px gray',
    marginBottom: '50px',
};

const divTotalBill = {
    marginRight: '100px',
}

const divTotalBillAmount = {
    borderBottom: 'solid 2px gray',
    width: 'auto',
    height: '30px',
}

export default function BettingDetail() {
    const history = useHistory();

    const [reportData, setreportData] = useState();
    const [totalBill, setTotalBill] = useState(0);
    const [totalCommission, setTotalCommission] = useState();
    const [pending, setPending] = useState(false);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const debounced = useDebouncedCallback((action) => { action() }, 200);
    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"));

    const { state, setState } = useContext(RootContext);

    const getDataReport = () => {

        const data = {
            token: token,
            group: group.wbid,
            fromdate: fromDate,
            todate: toDate,
        };

        if (!token) { history.push("/login") };

        try {
            axiosInstance("agent_report", {
                method: "POST",
                data,
            })
                .then((res) => {
                    setState({
                        ...state,
                        group: res.data.data.additional_data.group,
                        menu: refactorStructureData(res.data.data.additional_data.menu),
                    });

                    setreportData(res.data.data.list);
                    setTotalBill(res.data.data.add_data.total_bill);
                    setTotalCommission(res.data.data.add_data.total_commission);
                    setPending(false);
                }).catch((err) => { });
        } catch (error) {

        }
    }

    useEffect(() => {

        getDataReport()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>
        <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
        <div className="px-3 md:px-8 h-auto -mt-24">
            <div className="container mx-auto max-w-full">
                <div className="grid grid-cols-1 px-4 mb-16">
                    <TableAgentReport
                        title="Agent report"
                        reportData={reportData}
                        pending={pending}
                        fromEvent={(event) => {
                            debounced(() => {

                                setFromDate(event.target.value);
                            })
                        }}
                        toEvent={(event) => {
                            debounced(() => {

                                setToDate(event.target.value);
                            })
                        }}
                        getReport={() => {

                            getDataReport();
                            setPending(true);
                        }}

                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => { }}
                    />
                </div>
                <div style={divTotal}>
                    <div style={divTotalBillAmount}>
                        {totalBill}
                    </div>
                    <div style={divTotalBill}>
                        Total Bill:
                    </div>
                </div>
            </div>
        </div>
    </>);
}