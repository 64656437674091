import React, { useContext, useEffect, useState } from "react";
import TableProviderWinLose from "components/TableLotteryWinLose";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import refactorStructureData from "../../helpers/refactorDataMenu";
import { RootContext } from '../../App'

export default function LotteryWinlose() {
  const history = useHistory();
  const [lotteryWinlose, setLotteryWinlose] = useState();
  const [pending, setPending] = useState(true);
  const { state, setState } = useContext(RootContext);
  const [marketList, setMarketList] = useState([]);
  const [market, setMarket] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const getData = () => {

    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"));

    const data = {
      token: token,
      group: group.wbid,
      market: market,
      fromdate: fromDate,
      todate: toDate,
    }

    if (!token) {

      history.push("/login");
    } else {

      try {

        axiosInstance("winlose_lottery", {
          method: "POST",
          data,
        })
          .then((res) => {

            //console.log(res.data.data.list)
            const responBody = res.data.data.list;
            const lotteryUnit = res.data.data.add_data.lottery_unit;

            setLotteryWinlose(responBody);
            setMarketList(lotteryUnit);
            setPending(false);

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

          })
          .catch((err) => {
            // localStorage.removeItem("access_token");
            // history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  useEffect(() => {

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market]);

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableProviderWinLose
              lotteryWinlose={lotteryWinlose}
              marketList={marketList}
              pending={pending}

              marketEvent={(event) => {

                //console.log("Provider: " + event.value);
                setMarket(event.value);
              }}

              fromEvent={(event) => {

                //console.log("From date: " + event.target.value);
                setFromDate(event.target.value);
              }}

              toEvent={(event) => {

                //console.log("to date: " + event.target.value);
                setToDate(event.target.value)

              }}

              getReportEvent={() => {

                setPending(true);
                getData();

              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
