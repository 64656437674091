/*
* komponen ini menggunakan material tailwind, jika ingin mengubah atau menambah
* lihat halaman ini sebagai referensi
* https://www.material-tailwind.com/docs/react/installation
*
*/

import { RootContext } from '../../App';
import axiosInstance from '../../config/axios';
import refactorStructureData from '../../helpers/refactorDataMenu';
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import TableMarketWinlose from '../../components/TableMarketWinlose';

export default function LotteryCancelBet() {

    const [listData, setListData] = useState();
    const [pending, setPending] = useState(true);
    const { state, setState } = useContext(RootContext);
    const history = useHistory();

    const group = JSON.parse(localStorage.getItem("group"));

    const getData = () => {

        const token = localStorage.getItem("access_token");

        const data = {

            token: token,
            group: group.wbid,
        }

        if (!token) {

            history.push("/login");
            console.log('Not Valid Token');

        } else {

            try {

                axiosInstance('lottery/cancelBet', {

                    method: "POST",

                    data,

                }).then((res) => {

                    if (res.data.code === 0) {

                        const data = res.data.data.list;

                        setListData(data);

                        setState({

                            ...state,

                            group: res.data.data.additional_data.group,

                            menu: refactorStructureData(res.data.data.additional_data.menu),

                        });

                        setPending(false);

                    } else {

                        Swal.fire(
                            'Fetch Data Failed',
                            'Please Refresh',
                            'warning'
                        )
                    }

                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            localStorage.removeItem("access_token");

                            history.push("/login");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        )
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                )

            }

        }

    };

    useEffect(() => {

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TableMarketWinlose
                data={listData}
                pending={pending}
            />

        </>

    );

}
