import React, { useContext, useEffect, useState } from "react";
import TablePlayer from "components/TablePlayer";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from 'use-debounce';
import refactorStructureData from "../../helpers/refactorDataMenu";
import { RootContext } from '../../App';

export default function PlayerList() {
  const history = useHistory();
  const [listPlayer, setListPlayer] = useState();
  const [optionsLevel, setOptionLevel] = useState([])
  const [pending, setPending] = useState(true);
  const [contact, setContact] = useState(null)
  const [username, setUsername] = useState(null)
  const [active, setActive] = useState(null)
  const [bankAccount, setBankAccount] = useState(null)
  const [bankNumber, setBankNumber] = useState(null)
  const [minimalBalance, setMinimalBalance] = useState(null)
  const [maksimalBalance, setMaksimalBalance] = useState(null)
  const [toRegisterDate, setToRegisterDate] = useState(null)
  const [fromRegisterDate, setFromRegisterDate] = useState(null)
  const [toLoginDate, setToLoginDate] = useState(null)
  const [fromLoginDate, setFromLoginDate] = useState(null)
  const [uplineReferral, setUpline] = useState(null)
  const [lastIP, setLastIP] = useState(null)
  const [level, setLevel] = useState(null)
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1)
  const { state, setState } = useContext(RootContext)


  const debounced = useDebouncedCallback(
    (action) => {
      action()
    },
    200
  );

  useEffect(() => {

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contact,
    username,
    active,
    bankAccount,
    bankNumber,
    minimalBalance,
    maksimalBalance,
    toRegisterDate,
    fromRegisterDate,
    toLoginDate,
    fromLoginDate,
    uplineReferral,
    lastIP,
    level,
    perPage,
    currentPage,
  ]);

  const getData = () => {

    const token = localStorage.getItem("access_token");

    const group = JSON.parse(localStorage.getItem("group"))
    const data = {
      token: token,
      group: group.wbid,
      cont: contact,
      user: username,
      stat: active,
      bacc: bankAccount,
      bnum: bankNumber,
      bala: minimalBalance,
      bal2: maksimalBalance,
      reda: fromRegisterDate,
      reda2: toRegisterDate,
      lalo: fromLoginDate,
      lalo2: toLoginDate,
      upline: uplineReferral,
      laip: lastIP,
      levl: level,
      perpage: perPage,
      page: currentPage
    }
    if (!token) {
      history.push("/login");
    } else {
      try {

        axiosInstance("get_user_list", {
          method: "POST",
          data,
        })
          .then((res) => {
            // console.log(res) 
            const data = res.data.data.list
            const totalRow = res.data.data.add_data.total_row
            setListPlayer(data)
            setPending(false);
            setTotalRows(totalRow);

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

            const level = res.data.data.adv_search.level
            level.unshift({ leid: null, name: 'All' })
            setOptionLevel(level)

          })
          .then(() => {
          })
          .catch((err) => {
            // localStorage.removeItem("access_token");
            // history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };


  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TablePlayer
              title="Player List"
              listPlayer={listPlayer}
              optionsLevel={optionsLevel}
              pending={pending}
              totalRows={totalRows}
              perPage={perPage}

              contactEvent={(event) => {
                debounced(() => setContact(event.target.value))

              }}
              usernameEvent={(event) => {

                debounced(() => setUsername(event.target.value))

              }}
              activeEvent={(event) => {
                setActive(event.value)
              }}
              bankAccountEvent={(event) => {
                debounced(() => setBankAccount(event.target.value))


              }}
              bankNumberEvent={(event) => {
                debounced(() => setBankNumber(event.target.value))
              }}
              minimalBalanceEvent={(event) => {
                debounced(() => setMinimalBalance(event.target.value))


              }}
              maksimalBalanceEvent={(event) => {
                debounced(() => setMaksimalBalance(event.target.value))

              }}
              toRegisterDateEvent={(event) => {
                debounced(() => setToRegisterDate(event.target.value))

              }}
              fromRegisterDateEvent={(event) => {
                debounced(() => setFromRegisterDate(event.target.value))


              }}
              fromLoginDateEvent={(event) => {
                debounced(() => setFromLoginDate(event.target.value))


              }}
              toLoginDateEvent={(event) => {
                debounced(() => setToLoginDate(event.target.value))


              }}

              uplineReferral={(event) => {
                debounced(() => setUpline(event.target.value))
              }}

              lastIP={(event) => {
                debounced(() => setLastIP(event.target.value))
              }}

              handlePerRowsChange={(newPerPage) => {
                setPerPage(newPerPage)
              }}

              handlePageChange={(page) => {

                setCurrentPage(page);
              }}

              levelEvent={(event) => {
                setLevel(event.value)

              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
