import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import InputIcon from "@material-tailwind/react/InputIcon";
import Button from "@material-tailwind/react/Button";

const green = {
    color: 'green'
};

const red = {
    color: 'red'
};

const flexCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

export default function CardTable({ reportData, pending, getReport, fromEvent, toEvent }) {
    const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const columns = [
        {
            name: "Type",
            // width: '150px',
            selector: (row, index) => row.type,
            sortable: true,
        }, {
            name: "Turnover",
            // width: '150px',
            selector: (row, index) => addCommas(row.turnover),
            sortable: true,
        }, {
            name: "Player Winlose",
            // width: '150px',
            selector: (row, index) => {

                if (row.winlose > 0) {

                    return <div style={green}>{addCommas(row.winlose)}</div>
                }

                return <div style={red}>{addCommas(row.winlose)}</div>
            },
            sortable: true,
            right: true
        }, {
            name: "Commission",
            // width: '150px',
            selector: (row, index) => addCommas(row.commision),
            sortable: true,
            right: true
        }, {
            name: "Bill",
            // width: '150px',
            selector: (row, index) => addCommas(row.bill),
            sortable: true,
            right: true
        },
    ];

    const monthMapping = {
        January: 0,
        February: 1,
        March: 2,
        April: 3,
        May: 4,
        June: 5,
        July: 6,
        August: 7,
        September: 8,
        October: 9,
        November: 10,
        December: 11
    };

    const period = [
        {
            value: 0,
            label: 'January',
        }, {
            value: 1,
            label: 'Febuary',
        }, {
            value: 2,
            label: 'March',
        }, {
            value: 3,
            label: 'April',
        }, {
            value: 4,
            label: 'May',
        }, {
            value: 5,
            label: 'June',
        }, {
            value: 6,
            label: 'July',
        }, {
            value: 7,
            label: 'Auguest',
        }, {
            value: 8,
            label: 'September',
        }, {
            value: 9,
            label: 'Oktober',
        }, {
            value: 10,
            label: 'November',
        }, {
            value: 10,
            label: 'December',
        },
    ]

    return (
        <>
            <Card>
                <h5>Filter</h5>
                <CardBody>
                    <div style={{ width: '50%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '5px' }}>
                        <p>Date</p>
                        <InputIcon
                            type="date"
                            color="lightBlue"
                            size="sm"
                            outline={true}
                            placeholder="From date"
                            iconName=""
                            onChange={fromEvent}
                        />
                        <InputIcon
                            type="date"
                            color="lightBlue"
                            size="sm"
                            outline={true}
                            placeholder="To"
                            iconName=""
                            onChange={toEvent}
                        />
                        <div style={flexCenter}>
                            <Button
                                color="lightBlue"
                                buttonType="filled"
                                size="sm"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                                onClick={getReport}
                            >
                                Get Report
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Card className="mt-10">
                <CardHeader color="blueGray" contentPosition="left">
                    <h2 className="text-white text-2xl">Agent Report</h2>
                </CardHeader>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={reportData}
                        progressPending={pending}
                    // paginationRowsPerPageOptions={[10, 50, 100, 500]}
                    // onChangeRowsPerPage={onChangeRowsPerPage}
                    // pagination
                    />
                </CardBody>
            </Card >
        </>
    );
}