import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";

export default function TableLotteryTransaction({ data, pending, totalRows, handlePageChange, handlePerRowsChange, perPage }) {

    const columns = [
        {
            name: "Username",
            selector: (row) => row.username,
            width: "180px",
            sortable: true,
        },
        {
            name: "Market",
            selector: (row) => row.market,
            width: "120px",
            sortable: true,
        },
        {
            name: "Periode",
            selector: (row) => row.priode,
            sortable: true,
        },
        {
            name: "Date Time GMT(+7)",
            selector: (row) => {
                const date = new Date(row.created_at);

                if (isNaN(date.getTime())) {
                    return "Invalid ISO date string";
                }

                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');

                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            },
            width: "180px",
            sortable: true,
        },
        {
            name: "Bet",
            selector: (row) => row.bet,
            sortable: true,
        },
        {
            name: "Number",
            selector: (row) => row.number,
            sortable: true,
        },
        {
            name: "Type",
            selector: (row) => row.type,
            sortable: true,
        },
        {
            name: "Pay",
            selector: (row) => row.bayar,
            sortable: true,
        }
    ];

    return (
        <>
            <Card>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={pending}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 100, 500, 1000]}
                    />
                </CardBody>
            </Card>
        </>
    );
}

