import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";

export default function TableDomainConfig({
  domainList,
  pending,
  editEvent,
  deleteEvent,
}) {

  const handleClick = (nama) => {
    alert(`Tombol diklik oleh ${nama}!`);
  };

  const columns = [
    {
      name: "Domain",
      width: '180px',
      selector: (row) => row.domain,
      sortable: true,
    },
    {
      name: "title",
      width: '180px',
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "description",
      width: '180px',
      selector: (row) => row.description,
      sortable: true,
      right: true
    },
    {
      name: "keywords",
      width: '180px',
      selector: (row) => row.keywords,
      sortable: true,
      right: true
    },
    {
      name: "google code",
      width: '180px',
      selector: (row) => row.google_code,
      sortable: true,
      right: true
    },
    {
      name: "Edit",
      width: '180px',
      selector: (row) => {

        const idDomain = row.id;

        return <div>
          <Button
            color="green"
            buttonType="filled"
            size="sm"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
            onClick={() => editEvent(row.id)}
          >
            Edit
          </Button>
        </div>
      },
      sortable: true,
      right: true
    },
    {
      name: "Delete",
      width: '180px',
      selector: (row) => (
        <div>
          <Button
            color="deepOrange"
            buttonType="filled"
            size="sm"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
            onClick={() => deleteEvent(row.id)}
          >
            Delete
          </Button>
        </div>
      ),
      sortable: true,
      right: true
    },
  ];

  return (
    <>

      <Card className="mt-10">
        <CardHeader color="blueGray" contentPosition="none">

          <div className="w-full flex items-center justify-between">

            <h2 className="text-white text-2xl">Domain List</h2>

            <Button
              className="h-10
                px-5 m-2
                text-green-100 
                transition-colors 
                duration-150 
                bg-green-700 
                rounded-lg 
                focus:shadow-outline 
                hover:bg-green-800"
              onClick={() => {

                //setSaving(true);

                //handleSave();
                window.location.href = "/web_management/seo/add_domain_config";

              }}

            >
              Add Domain

            </Button>

          </div>

        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            data={domainList}
            progressPending={pending}
          />
        </CardBody>
      </Card >
    </>
  );
}
