import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";
import Select from 'react-select';

const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export default function CardTable({
  providerList,
  providerWinlose,
  pending,
  providerEvent,
  fromEvent,
  toEvent,
  getReportEvent,
  onChangeRowsPerPage,
}) {

  const columns = [
    {
      name: "Game Provider",
      width: '180px',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Turnover",
      width: '180px',
      selector: (row) => <tt><font color={"green"}>{row.total_to.toLocaleString("id-ID")}</font></tt>,
      sortable: true,
    },
    {
      name: "Player Winlose",
      width: '180px',
      selector: (row) => <tt><font color={row.total_winlose < 0 ? "red" : "green"}>{row.total_winlose.toLocaleString("id-ID")}</font></tt>,
      sortable: true,
      right: true
    },
  ];

  return (
    <>
      <Card>
        <h5>Advanced Search</h5>
        <CardBody>
          <div className="flex gap-2" style={{ marginBottom: '40px' }}>
            {/* <div style={{ width: '25%' }}>
              <InputIcon
                type="text"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="Provider"
                iconName="search"
                onChange={providerEvent}
              />
            </div> */}
            <div style={{ width: '25%' }}>
              <Select options={providerList.map((provider) => {
                return {
                  value: provider.name,
                  label: provider.name
                }
              })
              }
                onChange={providerEvent}
                placeholder="Game Provider" />
            </div>

          </div>

          <div style={{ width: '50%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '5px' }}>
            <p>Date</p>
            <InputIcon
              type="date"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="From date"
              iconName=""
              onChange={fromEvent}
            />
            <InputIcon
              type="date"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="To"
              iconName=""
              onChange={toEvent}
            />
            <div style={flexCenter}>
              <Button
                color="lightBlue"
                buttonType="filled"
                size="sm"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="light"
                onClick={getReportEvent}
              >
                Get Report
              </Button>
            </div>
          </div>

        </CardBody>
      </Card>
      <Card className="mt-10">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Provider Winlose</h2>
        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            data={providerWinlose}
            progressPending={pending}
            paginationRowsPerPageOptions={[50, 100, 500]}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination
            paginationPerPage={50}
          />
        </CardBody>
      </Card >
    </>
  );
}
