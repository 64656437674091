import React, { useContext, useEffect, useState } from "react";
import TableProviderWinLose from "components/TableProviderWinLose";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from 'use-debounce';
import refactorStructureData from "../../helpers/refactorDataMenu";
import { RootContext } from '../../App'

export default function PLPLProvider() {
  const history = useHistory();
  const [providerWinlose, setProviderWinlose] = useState();
  const [pending, setPending] = useState(true);
  const [provider, setProvider] = useState(null);
  const [length, setLength] = useState(100);
  const { state, setState } = useContext(RootContext);
  const [providerList, setProviderList] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const debounced = useDebouncedCallback(
    (action) => {
      action()
    },
    200
  );

  const getData = () => {

    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"));

    const data = {
      token: token,
      group: group.wbid,
      provider: provider,
      fromdate: fromDate,
      todate: toDate,
      lgth: length,
    }

    if (!token) {

      history.push("/login");
    } else {

      try {

        axiosInstance("provider_winlose", {
          method: "POST",
          data,
        })
          .then((res) => {

            //console.log(res.data.data.list)
            const responBody = res.data.data.list;
            const listProvider = res.data.data.add_data.provider_list;

            setProviderWinlose(responBody);
            setProviderList(listProvider);
            setPending(false);

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

          })
          .then(() => {
          })
          .catch((err) => {
            // localStorage.removeItem("access_token");
            // history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  useEffect(() => {

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, length]);

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableProviderWinLose
              providerWinlose={providerWinlose}
              providerList={providerList}
              pending={pending}

              providerEvent={(event) => {

                console.log("Provider: " + event.value);
                setProvider(event.value);
              }}

              fromEvent={(event) => {

                console.log("From date: " + event.target.value);
                setFromDate(event.target.value);
              }}

              toEvent={(event) => {

                console.log("to date: " + event.target.value);
                setToDate(event.target.value)

              }}

              getReportEvent={() => {

                setPending(true);
                getData();

              }}

              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setLength(currentRowsPerPage)
              }}

            />
          </div>
        </div>
      </div>
    </>
  );
}
