import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";

const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export default function CardTable({ dataPlayerWinlose,
  pending,
  usernameEvent,
  onChangeRowsPerPage,
  fromdateEvent,
  todateEvent,
  getReport
}) {

  const columns = [
    {
      id: 'username',
      name: "username",
      width: '150px',
      selector: (row, index) => row.username,
      sortable: true,
    },
    {
      id: 'turnover',
      name: "TurnOver",
      width: '150px',
      selector: 'turnover',
      cell: (row, index) => row.total_to.toLocaleString(),
      sortable: true,
    },
    {
      id: 'winlose',
      name: "Player Winlose",
      width: '180px',
      selector: 'winlose',
      cell: (row, index) => <tt><font color={row.total_winlose < 0 ? "red" : "black"}>{row.total_winlose.toLocaleString()}</font></tt>,
      sortable: true,
      right: true
    },
  ];

  return (
    <>
      <Card>
        <h5>Advanced Search</h5>
        <CardBody>
          <div className="flex gap-2">
            <div style={{ width: '25%' }}>
              <InputIcon
                type="text"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="Username"
                iconName="search"
                onChange={usernameEvent}
              />
            </div>
            <div style={{ width: '50%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '5px' }}>
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="From Date"
                iconName=""
                onChange={fromdateEvent}
              />
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="To Date"
                iconName=""
                onChange={todateEvent}
              />
            </div>
          </div>

          <div style={flexCenter}>
            <Button
              color="lightBlue"
              buttonType="filled"
              size="sm"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="light"
              onClick={getReport}
            >
              Get Report
            </Button>
          </div>

        </CardBody>
      </Card>
      <Card className="mt-10">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Player Winlose</h2>
        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            data={dataPlayerWinlose}
            progressPending={pending}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination
          />
        </CardBody>
      </Card >
    </>
  );
}
