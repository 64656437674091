import React, { useContext, useEffect, useState } from "react";
import TableDomainConfig from "components/TableDomainConfig";
import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import { RootContext } from '../../../App';
import axiosInstance from '../../../config/axios';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import Button from "@material-tailwind/react/Button";
import Swal from "sweetalert2";

export default function ListDomain() {
  const history = useHistory();
  const [pending, setPending] = useState(true);
  const { state, setState } = useContext(RootContext);
  const [domainList, setDomainList] = useState([]);

  const handleDelete = (id) => {

    console.log("data di hapus " + id)

    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"));

    const data = {
      token: token,
      group: group.wbid,
      id: id
    }

    if (!token) {

      history.push("/login");
    } else {

      try {

        axiosInstance("delete_meta_tag", {
          method: "POST",
          data,
        })
          .then((res) => {

            if (res.data.code === 0) {

              Swal.fire(
                'Deleted',
                'delete domain success',
                'success',
              ).then(() => {

                window.location.reload();
              });
            }else{

              Swal.fire(
                'Failed',
                'data domain not deleted',
                'error',
              );
            }

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

          })
          .catch((err) => {
            // localStorage.removeItem("access_token");
            // history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  }

  const getData = () => {

    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"));

    const data = {
      token: token,
      group: group.wbid,
    }

    if (!token) {

      history.push("/login");
    } else {

      try {

        axiosInstance("get_meta_tag", {
          method: "POST",
          data,
        })
          .then((res) => {

            //console.log(res.data.data.list)
            const responBody = res.data.data.list;

            setDomainList(responBody);
            setPending(false);

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

          })
          .catch((err) => {
            // localStorage.removeItem("access_token");
            // history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  useEffect(() => {

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto">

      </div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableDomainConfig
              domainList={domainList}
              pending={pending}

              editEvent={(id) => {

                console.log("Event Edit: " + id);
                return window.location.href = '/web_management/seo/edit_domain_config/' + id;
              }}

              deleteEvent={(id) => {

                handleDelete(id);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
