/*
* komponen ini menggunakan material tailwind, jika ingin mengubah atau menambah
* lihat halaman ini sebagai referensi
* https://www.material-tailwind.com/docs/react/installation
*
*/

import { RootContext } from '../../../App';
import axiosInstance from '../../../config/axios';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import Image from "@material-tailwind/react/Image";
import { Textarea } from '@material-tailwind/react';

export default function AddDomainConfig() {

    const { state, setState } = useContext(RootContext);
    const history = useHistory();
    const param = useParams();
    const [saving, setSaving] = useState();
    const [domain, setDomain] = useState();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [keywords, setKeywords] = useState();
    const [googleCode, setGoogleCode] = useState();
    const group = JSON.parse(localStorage.getItem("group"));

    const handleSave = () => {

        console.log(domain)

        if(domain === undefined){

            console.log("domain 0")

            Swal.fire(
                'Failed',
                'domain must filled',
                'error',
            );

            return
        }

        if(title === undefined){

            Swal.fire(
                'Failed',
                'title must filled',
                'error',
            );

            return
        }

        if(description === undefined){

            Swal.fire(
                'Failed',
                'description must filled',
                'error',
            );

            return
        }

        if(keywords === undefined){

            Swal.fire(
                'Failed',
                'keywords must filled',
                'error',
            );

            return
        }

        if(googleCode === undefined){

            Swal.fire(
                'Failed',
                'google code must filled',
                'error',
            );

            return
        }

        const token = localStorage.getItem("access_token");

        if (!token) {

            history.push("/login");
            console.log('Not Valid Token');

        } else {

            console.log("memangil server")

            try {

                const data = {
                    group: group.wbid,
                    token: token,
                    domain: domain,
                    title: title,
                    description: description,
                    keywords: keywords,
                    google_code: googleCode,
                }

                axiosInstance('add_meta_tag', {

                    method: "POST",

                    data,

                }).then((res) => {

                    localStorage.setItem("access_token", res.data.data.token);

                    if (res.data.code === 0) {

                        Swal.fire(
                            'Saved',
                            'Data Saved',
                            'success',
                        ).then(() => {

                            setSaving(false);

                            window.location.href = "/web_management/seo/domain_config";
                        });
                    } else {

                        Swal.fire(
                            'Failed',
                            'Failed To save',
                            'error',
                        );

                        setSaving(false);
                        console.log('Error Mesage: ' + res.data.message);
                    }

                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            console.log("Token Expire 401");
                            localStorage.removeItem("access_token");

                            history.push("/login");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        ).then(() => {

                            setSaving(false);

                        })
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                ).then(() => {

                    setSaving(false);

                })

            }

        }
    }

    const getData = () => {

        const token = localStorage.getItem("access_token");

        const data = {

            token: token,
            group: group.wbid,

        }

        if (!token) {

            history.push("/login");
            console.log('Not Valid Token');

        } else {

            try {

                axiosInstance('get_menu', {

                    method: "POST",

                    data,

                }).then((res) => {

                    setState({

                        ...state,

                        group: res.data.data.additional_data.group,

                        menu: refactorStructureData(res.data.data.additional_data.menu),

                    });

                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            localStorage.removeItem("access_token");

                            history.push("/login");

                            console.log("2Token Expire 401");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        ).then(() => {

                            setSaving(false);

                        })
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                ).then(() => {

                    setSaving(false);

                })

            }

        }

    };

    useEffect(() => {

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>

            <Card>

                <CardHeader color="blueGray" contentPosition="none">

                    <div className="w-full flex items-center justify-between">

                        <h2 className="text-white text-2xl">Domain Config</h2>

                        <Button
                            className="h-10
                                    px-5 m-2
                                    text-green-100 
                                    transition-colors 
                                    duration-150 
                                    bg-green-700 
                                    rounded-lg 
                                    focus:shadow-outline 
                                    hover:bg-green-800"
                            onClick={() => {

                                setSaving(true);

                                handleSave();

                            }}

                        >
                            {!saving ? 'Save' : 'Saving'}

                        </Button>

                    </div>

                </CardHeader>

                <CardBody>


                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Domain

                    </h6>

                    <div className="flex flex-wrap mt-10">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Input
                                type="text"
                                color="purple"
                                placeholder=" "
                                defaultValue="domain name"
                                onChange={(e) => {

                                    setDomain(e.target.value);
                                }}
                            />

                        </div>

                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Title

                    </h6>

                    <div className="flex flex-wrap mt-10">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Input
                                type="text"
                                color="purple"
                                placeholder=" "
                                defaultValue="title"
                                onChange={(e) => {

                                    setTitle(e.target.value);

                                }}
                            />

                        </div>

                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Description

                    </h6>

                    <div className="flex flex-wrap mt-10">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Input
                                type="text"
                                color="purple"
                                placeholder=" "
                                defaultValue="description"
                                onChange={(e) => {

                                    setDescription(e.target.value);
                                }}
                            />

                        </div>

                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        keywords

                    </h6>

                    <div className="flex flex-wrap mt-10">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Input
                                type="text"
                                color="purple"
                                placeholder=" "
                                defaultValue="keywords"
                                onChange={(e) => {

                                    setKeywords(e.target.value);
                                }}
                            />

                        </div>

                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Google Code

                    </h6>

                    <div className="flex flex-wrap mt-10 font-light">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Input
                                color="purple"
                                placeholder=" "
                                defaultValue="google_code"
                                onChange={(e) => {

                                    setGoogleCode(e.target.value);
                                }}
                            />

                        </div>

                    </div>

                </CardBody>

            </Card>

        </>

    );

}
