import React, { useContext, useEffect, useState } from "react";
import TablePlayerWinLose from "components/TablePlayerWinLose";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from 'use-debounce';
import refactorStructureData from "../../helpers/refactorDataMenu";
import { RootContext } from '../../App'

export default function PLMemberList() {
  const history = useHistory();
  const [playerWinlose, setPlayerWinlose] = useState();
  const [pending, setPending] = useState(true);
  const [username, setUsername] = useState();
  const [fromdate, setFromdate] = useState();
  const [todate, setTodate] = useState()
  const [length, setLength] = useState(100)
  const { state, setState } = useContext(RootContext)


  const debounced = useDebouncedCallback(
    (action) => {
      action()
    },
    200
  );

  useEffect(() => {

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {

    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"));

    const data = {
      token: token,
      group: group.wbid,
      username: username,
      lgth: length,
      todate: todate,
      fromdate: fromdate,
    }

    if (!token) {

      history.push("/login");
    } else {

      try {

        axiosInstance("player_winlose", {
          method: "POST",
          data,
        })
          .then((res) => {

            console.log(res.data.data.list);
            const ResponBody = res.data.data.list;

            setPlayerWinlose(ResponBody);
            setPending(false);

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

          })
          .then(() => {
          })
          .catch((err) => {
            console.log("ERROR: ", err);
            // localStorage.removeItem("access_token");
            // history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TablePlayerWinLose
              dataPlayerWinlose={playerWinlose}
              pending={pending}
              getReport={() => {

                getData();
              }}

              usernameEvent={(event) => {

                debounced(() => setUsername(event.target.value))

              }}

              fromdateEvent={(event) => {

                setFromdate(event.target.value)

              }}

              todateEvent={(event) => {

                debounced(() => setTodate(event.target.value))

              }}

              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setLength(currentRowsPerPage)
              }}

            />
          </div>
        </div>
      </div>
    </>
  );
}
